import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import { Pagination, Navigation } from "swiper";





let BannerListContent = [
    {
        bgImgUrl: 'assets/images/banner/home-2/bgplay.jpg',
        title: "Wilgold's Playground",
        subtitle: '',
        subtitlecolor: 'visit the store',
        subtitle2: ' or learn',
        subtitlecolor2: ' more',
        desc: 'Coming Soon!',
        btnText: 'more ',
        btnLink: '/playground',

        imgUrl: 'assets/images/team/1.png',
        imgAlt: 'Player Thumb',
        
        gamelink: '/team-single',
        link: 'https://store.steampowered.com/app/2287050/Wilgolds_Playground',
        linkIMG: 'assets/images/match/steam.png',
        linkALT: 'Steam',
        show: 'true',
        link2: 'http://www.youtube.com/@wilgoldgames',
        linkIMG2: 'assets/images/match/steam2.png',
        linkALT2: 'YouTube',
        show2: 'none',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',
        showLocalBtn: '',
        showExternal: 'none',

    },
    {
        bgImgUrl: 'assets/images/banner/home-2/bg.jpg',
        title: 'DOWNLOAD',
        subtitle: 'the newest',
        subtitlecolor: ' wilgold',
        subtitle2: ' games',
        subtitlecolor2: '',
        desc: 'You can find all the shops and stores my games can be found on. Check them out by platform to find something you might like. You can also check out all the games I have here by browsing all games.',
        btnText: 'browse all games ',
        btnLink: '/games',

        imgUrl: 'assets/images/team/1.png',
        imgAlt: 'Player Thumb',
        
        gamelink: '/team-single',
        link: 'http://wilgold.itch.io',
        linkIMG: 'assets/images/match/itch.png',
        linkALT: 'Itch',
        show: 'true',
        link2: 'https://play.google.com/store/apps/dev?id=7707159057228301175',
        linkIMG2: 'assets/images/match/play.png',
        linkALT2: 'Google Play',
        show2: 'true',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',
        showLocalBtn: '',
        showExternal: 'none',

    },
    {
        bgImgUrl: 'assets/images/banner/home-2/bg-2.jpg',
        title: 'watch',
        subtitle: 'the',
        subtitlecolor: ' newest',
        subtitle2: ' videos on youtube',
        subtitlecolor2: '',
        desc: 'Find my latest videos on YouTube about making games and various ideas in Unreal.  ',
        btnText: 'YouTube/Wilgold ',
        btnLink: 'http://www.youtube.com/@wilgoldgames',
     

        imgUrl: 'assets/images/team/1.png',
        imgAlt: 'Player Thumb',
        
        gamelink: '/team-single',
        link: 'http://www.wilgold.com',
        linkIMG: 'assets/images/contact/yt3.png',
        linkALT: 'YouTube',
        show: 'none',
        link2: 'http://www.youtube.com/@wilgoldgames',
        linkIMG2: 'assets/images/match/steam2.png',
        linkALT2: 'YouTube',
        show2: 'none',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',
        showLocalBtn: 'none',
        showExternal: '',
    },
    {
        bgImgUrl: 'assets/images/banner/home-2/bg-5.jpg',
        title: 'Puppet Thread',
        subtitle: '',
        subtitlecolor: 'download',
        subtitle2: ' or learn',
        subtitlecolor2: ' more',
        desc: 'Get the game against puppets and boss battles. A fast paced Hack n Slash arcade, built with the spirit of a whack a mole game.',
        btnText: 'more ',
        btnLink: '/puppetthread',

        imgUrl: 'assets/images/team/1.png',
        imgAlt: 'Player Thumb',
        
        gamelink: '/team-single',
        link: 'https://play.google.com/store/apps/details?id=com.klaydesigngames.puppetthread',
        linkIMG: 'assets/images/match/play.png',
        linkALT: 'Play Store',
        show: 'true',
        link2: 'http://www.youtube.com/@wilgoldgames',
        linkIMG2: 'assets/images/match/steam2.png',
        linkALT2: 'YouTube',
        show2: 'none',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',
        showLocalBtn: '',
        showExternal: 'none',

    },
]

class BannerTwo extends Component {
    render() { 
        return (
            <div className="banner__slider overflow-hidden">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    loop={'true'}
                    autoHeight={true}
                    
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}

                    hashNavigation={{
                        watchState: true,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={true}
                      modules={[Pagination, Navigation, Autoplay]}
                      className="mySwiper"
                      dotColor="#000" activeDotColor="red"

                >
                {BannerListContent.map((val, i) => (
                        <SwiperSlide data-hash="slide1" key={i}>
                            <div className="banner" style={{backgroundImage: `url(${val.bgImgUrl})`}}>
                                <div className="container">
                                    <div className="row g-0">
                                        <div className="col-lg-6 col-12">
                                            <div className="banner__content">
                                                <h1>{val.title}</h1>
                                                <h2>{val.subtitle}
                                                <span class="theme-color text-uppercase"> {val.subtitlecolor}</span>
                                                {val.subtitle2}
                                                <span class="theme-color text-uppercase"> {val.subtitlecolor2}</span>
                                                </h2>
                                                <p>{val.desc}</p>


                                                <ul className="match-social-list d-flex flex-wrap ">
                                                    

                                                    <Fragment className="swiper-slide">

                                                   

                <li style={{ display: val.show }}>

            <a href={''+val.link} target="_blank"><img src={''+val.linkIMG} alt={''+val.linkALT}/></a>

                </li>


                <li style={{ display: val.show2 }}>

            <a href={''+val.link2} target="_blank"><img src={''+val.linkIMG2} alt={''+val.linkALT2}/></a>
                </li>

                <li style={{ display: val.show3 }}>

            <a href={''+val.link3} target="_blank"><img src={''+val.linkIMG3} alt={''+val.linkALT3}/></a>
                </li>
            </Fragment>
                                                    </ul>





                                                <a href={val.btnLink} target="_blank" style={{ display: val.showExternal }} className="default-button"><span>{val.btnText} 
                                                
                                                

                                                 <i className="icofont-play-alt-1"></i></span> </a>

                                                 <Link to={val.btnLink} style={{ display: val.showLocalBtn }} className="default-button"><span>{val.btnText} 
                                                
                                                

                                                <i className="icofont-play-alt-1"></i></span> </Link>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                    
                </Swiper>
            



            </div>
        );
    }
}
 
export default BannerTwo;