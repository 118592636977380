import { Component } from "react";
import { Link } from "react-router-dom";



const subtitle = "who am i";
const title = "i am an indie game developer";
const desc = "Just a single person residing in Europe, creating games and videos for everyone to enjoy.";




let AboutListContent = [
    {
        imgUrl: 'assets/images/about/icon-1.png',
        imgAlt: 'About Icon',
        title: 'The game',
        desc: 'I do what many others try to do. I work on my dream game! But it takes a long time... so in the meanwhile, I try to work on smaller projects that are unique and fun. This way I can go and explore in many directions in a short amount of time, and create something fresh each time for your entertainment. Follow me and see what I am currently working on.',
        show: 'none',
    },
    // {
    //     imgUrl: 'assets/images/about/icon-2.png',
    //     imgAlt: 'About Icon',
    //     title: '34m+ Registered Players',
    //     desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    // },
    {
        imgUrl: 'assets/images/about/icon-3.png',
        imgAlt: 'About Icon',
        title: 'Feedback',
        desc: 'I might work on something new and original, or I might revisit something I have already worked on, so if you have any comments or ideas, or you would like to see me do something, feel free to contact me and let me know!',
        show: '',
    },
]



class AboutSection extends Component {
    render() { 
        const {imgUrl} = this.props;
        return (
            <section className="about-section">
                <div className="container">
                    <div className="section-wrapper padding-top">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-image">
                                    <img src={'assets/images/about/05.png'} alt="about-image" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="about-wrapper">
                                    <div className="section-header">
                                        <p>{subtitle}</p>
                                        <h2>{title}</h2>
                                    </div>
                                    <div className="about-content">
                                        <p>{desc}</p>
                                        <ul className="about-list">
                                            {AboutListContent.map((val, i) => (
                                                <li className="about-item d-flex flex-wrap" key={i}>
                                                    <div className="about-item-thumb">
                                                        <img 
                                                            src={`${val.imgUrl}`} 
                                                            alt={`${val.imgAlt}`}
                                                        />
                                                    </div>

                                                    
                                                    <div className="about-item-content">
                                                        <h5>{val.title}</h5>
                                                        <p>{val.desc}</p>
                                                        <Link to="/contact" style={{ display: val.show }} className="default-button reverse-effect float-end"><span>Contact <i className="icofont-circled-right"></i></span> </Link>
                                                    </div>
                                                </li>

                                                   


                                            ))}
                                            
                                        </ul>
                                        
                                    </div>
                                    
                                </div>
                            </div>

                                    {/* <div className="button-wrapper text-center mt-5">
                                    <Link to="/games" className="default-button"><span>Contact <i className="icofont-circled-right"></i></span> </Link>
                                    </div> */}
                        </div>
                    </div>
                </div>

                
            </section>
        );
        
    }
}
 
export default AboutSection;