import { React, Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/section/googlemap";





import { Link } from "react-router-dom";
import { Button } from "react-scroll";


const infosubtitle = "Get in touch with me";
const infotitle = "I'm Always Eager To Hear From You!";
const contacttitle = "Fill The Form Below So We Can Get To Know You And Your Needs Better.";

var mto= "mailto:info@wilgold.com?subject=Subject&body=Hello!";



let infoListContent = [
    {
        imgUrl: 'assets/images/contact/icon/03.png',
        imgAlt: 'Contact Info Thumb',
        title: 'Send Email by clicking below',
        desc: 'yourmail@gmail.com',
    },
]

class ContactUs extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactNumber: '',
            contactMassage: '',
        };
    }

    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'CONTACT ME'} curPage={'CONTACT'} />
                <div className="info-section padding-top padding-bottom">
                    <div className="container">
                        <div className="section-header">
                            <p>{infosubtitle}</p>
                            <h2>{infotitle}</h2>
                        </div>
                        <div className="section-wrapper">
                            <div className="row justify-content-center g-4">
                                {infoListContent.map((val, i) => (
                                    <div className="col-lg-4 col-sm-6 col-12" key={i}>
                                        <div className="contact-item text-center">
                                            <div className="contact-thumb mb-4">
                                                <img 
                                                    src={`${val.imgUrl}`} 
                                                    alt={`${val.imgAlt}`} 
                                                />
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.title}</h6>
                                                {/* <p>{val.desc}</p> */}




                                                {/* <input type="button" onclick="window.open('mailto:your@email.address?subject=Comments about the color blue');" value="Contact us" /> */}




                                                {/* <h2>(jQuery and) iFrame</h2> */}
    {/* <input type="button" onclick="addMailIframe();" value="Contact us" /> */}


 

    <a type="button" className="" value="Contact us" href={`${mto}`}><h2 className="theme-color" >E-mail</h2></a>





                                                
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

               
                <Footer />
            </Fragment>
        );
    }
}
 
export default ContactUs;