import React, { useState, useCallback } from "react";
import { createRoot } from "react-dom/client";
import ImageViewer from "react-simple-image-viewer";

import Fancybox from "./fancybox.jsx";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";

  export default function LightBoxW() {
    return (
      <div className="LightBoxW d-flex flex-wrap align-items-center justify-content-center flex flex-wrap gap-5 justify-center max-w-3xl mx-auto px-6">
        <h2 className="padding-top" >Hello CodeSandbox</h2>
        <div class="break"></div> 
        <Fancybox>
          <p>
            <a data-fancybox="gallery" href="https://lipsum.app/id/33/1024x768">
              <img alt="" src="https://lipsum.app/id/33/200x150" />
            </a>
    
            <a data-fancybox="gallery" href="https://lipsum.app/id/34/1024x768">
              <img alt="" src="https://lipsum.app/id/34/200x150" />
            </a>
    
            <a data-fancybox="gallery" href="https://lipsum.app/id/35/1024x768">
              <img alt="" src="https://lipsum.app/id/35/200x150" />
            </a>
    
            <a data-fancybox="gallery" href="https://lipsum.app/id/36/1024x768">
              <img alt="" src="https://lipsum.app/id/36/200x150" />
            </a>
          </p>

          <p>
            <button data-fancybox="dialog" data-src="#dialog-content">Launch Dialog</button>
          </p>
          <div id="dialog-content" style={{display:"none",maxWidth:"500px"}}>
            <h2>Hello, world!</h2>
            <p>
              <input type="text" value="" />
            </p>
            <p>
              Try hitting the tab key and notice how the focus stays within the dialog itself.
            </p>
            <p>
              To close dialog hit the esc button, click on the overlay or just click the close button.
            </p>
            <p className="margin-bottom--none">
              Element used to launch the modal would receive focus back after closing.
            </p>
          </div>
        </Fancybox>
      </div>
    );
    }
    