import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheadertwo";
import SocialMedia from "../component/section/socialmedia";
import Example from "../component/modal/modalbutton-test";
import ModalButton from "../component/modal/modalbutton-test";

import { createRoot } from "react-dom/client";
import ImageViewer from "react-simple-image-viewer";

import Fancybox from "./fancybox.jsx";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import { blue } from "@mui/material/colors";


import ScrollTop from "react-scrolltop-button";


const subtitle = "WILGOLD'S PLAYGROUND";
const title = "ABOUT THE GAME";
const desc = "Coming Soon";

const playerTitle = "TEAM Members";

let WinPriceList = [
    {
        icon: 'icofont-medal',
        title: '15 WINS',
    },
    {
        icon: 'icofont-signal',
        title: '04 LOSES',
    },
    {
        icon: 'icofont-game',
        title: '05 DRAWS',
    },
    {
        icon: 'icofont-workers-group',
        title: '02 PLAYS',
    },
]


let PlayerList = [
    {
        imgUrl: 'assets/images/player/01.jpg',
        imgAlt: 'Player Thumb',
        pName: 'Guadalupe R. Johnson',
    },
    {
        imgUrl: 'assets/images/player/02.jpg',
        imgAlt: 'Player Thumb',
        pName: 'AMADO R. MARTIN',
    },
    {
        imgUrl: 'assets/images/player/03.jpg',
        imgAlt: 'Player Thumb',
        pName: 'KYLE S. STELLY',
    },
    {
        imgUrl: 'assets/images/player/04.jpg',
        imgAlt: 'Player Thumb',
        pName: 'JOHN T. TONEY',
    },
    {
        imgUrl: 'assets/images/player/05.jpg',
        imgAlt: 'Player Thumb',
        pName: 'CHARLES S. ELLIOTT',
    },
    {
        imgUrl: 'assets/images/player/06.jpg',
        imgAlt: 'Player Thumb',
        pName: 'CLAUDE M. VASQUEZ',
    },
]


class Playground extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                {/* <PageHeaderTwo /> */}

<div className="bg-attachment" style={{backgroundColor: blue}}>

                <section className="pageheader-section" style={{backgroundImage: "url(/assets/images/pageheader/playground_header.jpg)"}}>
                <div className="container">
                    <div className="section-wrapper text-center text-uppercase">
                        <div className="pageheader-thumb mb-4">
                            <img src="assets/images/team/8.png" alt="team" />
                        </div>
                        <h2 className="pageheader-title">Wilgold's Playground</h2>
                    </div>


                    <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                        <Fragment className="swiper-slide">
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.klaydesigngames.puppetthread" target="_blank"><img src="assets/images/match/play.png" alt="Play Store"/></a>
                            </li>
                        </Fragment>
                    </ul>

                </div>
            </section>

<br />
            <div className="about-team "style={{
        // backgroundColor: '#d99a2e',

      }}>
                    <div className="container">
                        <div className="section-header">
                            <p>{subtitle}</p>
                            <h2 className="mb-3">{title}</h2>
                            <p className="desc">{desc}</p>
                            <br />
                        </div>
                       
                    </div>
                </div>



                {/* <ModalButton /> */}
             
{/* 
                bg-attachment
                style={{backgroundImage: "url(/assets/images/games/pt/bg3.jpg"}} */}



                <div className="LightBoxW d-flex section-header flex-wrap align-items-center justify-content-center flex flex-wrap gap-5 justify-center max-w-3xl mx-auto px-6" >
       
        <p >pictures</p>
        <div class="break"></div> 
        <Fancybox>
          <p>
            <a data-fancybox="gallery" href="assets/images/games/pt/01.jpg">
              <img alt="" src="assets/images/games/pt/01s.jpg" />
            </a>
    
            <a data-fancybox="gallery" href="assets/images/games/pt/02.jpg">
              <img alt="" src="assets/images/games/pt/02s.jpg" />
            </a>
    
            <a data-fancybox="gallery" href="assets/images/games/pt/03.jpg">
              <img alt="" src="assets/images/games/pt/03s.jpg" />
            </a>
    
            <a data-fancybox="gallery" href="assets/images/games/pt/04.jpg">
              <img alt="" src="assets/images/games/pt/04s.jpg" />
            </a>
            <a data-fancybox="gallery" href="assets/images/games/pt/05.jpg">
              <img alt="" src="assets/images/games/pt/05s.jpg" />
            </a>
            <a data-fancybox="gallery" href="assets/images/games/pt/06.jpg">
              <img alt="" src="assets/images/games/pt/06s.jpg" />
            </a>

          </p>

        </Fancybox>
      </div>


      <ScrollTop
                    text="Scroll to top"
                    distance={100}
                    breakpoint={4555}
                    style={{ backgroundColor: "#e6a52d" }}
                    className="scroll-your-role"
                    speed={20}
                    target={0}
                    // icon={<MyIcon />}
                />


      <div className="about-team " >
                    <div className="container">
                        <div className="section-header">
                            <p className="">Videos</p>
<br />
                            <iframe width="auto" height="450" src="https://www.youtube.com/embed/rNVofAW6IVI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                        </div>
                       
                    </div>
                </div>

                <div className="about-team " >
                    <div className="container">
                        <div className="section-header">
                            <p className="">Extras</p>
<br />


<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay"  src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/532433010&color=%23e6a52d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" ></iframe>

                        </div>
                       
                    </div>
                </div>

                <div className="about-team ">
                    <div className="container">
                        <div className="section-header">
                            <p className="">Get the game here</p>
                            <h2 className="">DOWNLOAD</h2>
<br />


<ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                        <Fragment className="swiper-slide">
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.klaydesigngames.puppetthread" target="_blank"><img src="assets/images/match/play.png" alt="Play Sotre"/></a>
                            </li>
                        </Fragment>
                    </ul>


                        </div>
                       
                    </div>
                </div>









                <Footer />


                </div>

            </Fragment>

            
        );
    }
}
 
export default Playground;