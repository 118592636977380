import { Component } from "react";
import { NavLink, Link } from 'react-router-dom';

// import ScrollTop from "react-scrolltop-button";

import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { library, icon } from '@fortawesome/fontawesome-svg-core'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
// import { fatiktok } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
// import { fab } from '@fortawesome/free-brands-svg-icons'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {useRef} from 'react';


import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faTiktok, faFontAwesome } from '@fortawesome/free-brands-svg-icons'

import * as Scroll from 'react-scroll';
import { Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'

<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"></link>

library.add(faCamera)
library.add(faCoffee)
library.add(fas, faTwitter, faTiktok, faFontAwesome)
// library.add(faTiktok)
// library.add(fab)
// library.add(fatiktok)
const camera = icon({ prefix: 'fas', iconName: 'camera' })


const element = <FontAwesomeIcon icon={faCoffee} />

const contactNumber = "";
const contactAddress = "";



let SocialMideaList = [
    {
        IconName: 'icofont-youtube',
        IconLink: 'https://youtube.com/@wilgoldgames',
        IMG: '',
    },
    {
        IconName: 'fa-brands fa-tiktok',
        IconLink: 'https://www.tiktok.com/@wilgoldgames',
        IMG: 'fa-brands fa-tiktok',
    },
    {
        IconName: 'icofont-facebook',
        IconLink: 'https://www.facebook.com/wilgoldentertainment',
        IMG: '',
    },
    {
        IconName: 'icofont-twitter',
        IconLink: 'https://twitter.com/WilgoldGames',
        IMG: '',
    },


    {
        IconName: 'icofont-soundcloud',
        IconLink: 'https://soundcloud.com/wilgold',
        IMG: '',
    },
    {
        IconName: 'icofont-coffee-cup',
        IconLink: 'https://www.buymeacoffee.com/wilgold',
        IMG: '',
    },






]

class Header extends Component {
    menuTrigger() {
        document.querySelector('.menu').classList.toggle('active')
        document.querySelector('.header-bar').classList.toggle('active')
    }
    menuTriggerTwo() {
        document.querySelector('.header-top').classList.toggle('open')
        // document.querySelector('.header-bar').classList.toggle('active')
    }

    render() { 
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header-section').classList.add(['header-fixed'], ['fadeInUp'])
            }else{
                document.querySelector('.header-section').classList.remove(['header-fixed'], ['fadeInUp'])
            }
        });

        return (
            <header className="header-section">
                <div className="container">
                    <div className="header-holder d-flex flex-wrap justify-content-between align-items-center">
                        <div className="brand-logo d-none d-lg-inline-block">
                            <div className="logo">
                                <Link to="/">
                                    <img src="assets/images/logo/logowilgold2.png" alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="header-menu-part">
                            <div className="header-top">
                                <div className="header-top-area">
                                    <ul className="left">
                                        {/* <li>
                                            <i className="icofont-ui-call"></i> <span>{contactNumber}</span>
                                        </li>
                                        
                                        <li>
                                            <i className="icofont-location-pin"></i> {contactAddress}
                                        </li> */}
                                    </ul>
                                    <ul className="social-icons d-flex align-items-center">
                                        {SocialMideaList.map((val, i) => (
                                            <li key={i}>
                                                <a href={`${val.IconLink}`} target="_blank" className="fb">  <i className={`${val.IconName}`}><FontAwesomeIcon icon={`${val.IconName}`} /></i></a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="header-bottom">

                            {/* <FontAwesomeIcon icon="fa-solid fa-camera" />
                            <FontAwesomeIcon icon="fa-solid fa-coffee" />
                            <FontAwesomeIcon icon="fa-solid fa-tiktok" />
                            <FontAwesomeIcon icon={["fab", "github"]} />
                            <FontAwesomeIcon icon="fa-brands fa-twitter" />
                            <FontAwesomeIcon icon="fa-brands fa-tiktok" /> */}

                                <div className="header-wrapper justify-content-lg-end">

                                    
                                    <div className="mobile-logo d-lg-none">
                                        <Link to="/"><img src="assets/images/logo/logowilgold2.png" alt="logo" /></Link>
                                        




                                        
                                    </div>
                                    <div className="menu-area">
                                        <ul className="menu">

                                        <li><NavLink to="/">Home</NavLink></li>
                                        <li><NavLink to="/about">About</NavLink></li>
                                        <li><NavLink to="/games">Games</NavLink></li>
                                        {/* <li><NavLink to="/game-list">Videos</NavLink></li> */}


                                            {/* <li className="menu-item-has-children">
                                                <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Home</a>
                                                <ul className="submenu dropdown-menu">
                                                    <li><NavLink to="/">Home One</NavLink></li>
                                                    <li><NavLink to="/index-2">Home Two</NavLink></li>
                                                </ul>
                                            </li> */}

                                            {/* <li className="menu-item-has-children">
                                                <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Features</a>
                                                <ul className="submenu dropdown-menu" aria-labelledby="dropdown" >
                                                    <li><NavLink to="/about">About</NavLink></li>
                                                    <li><NavLink to="/gallery">gallery</NavLink></li>
                                                    <li><NavLink to="/game-list">game list 1</NavLink></li>
                                                    <li><NavLink to="/game-list2">game list 2</NavLink></li>
                                                    <li><NavLink to="/partners">partners</NavLink></li>
                                                    <li><NavLink to="/achievements">achievement</NavLink></li>
                                                    <li><NavLink to="/team">team</NavLink></li>
                                                    <li><NavLink to="/team-single">team single</NavLink></li>
                                                    <li><NavLink to="/404">404 Page</NavLink></li>
                                                </ul>
                                            </li> */}
                                            {/* <li className="menu-item-has-children">
                                                <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Shop</a>
                                                <ul className="submenu dropdown-menu">
                                                    <li><NavLink to="/shop">shop</NavLink></li>
                                                    <li><NavLink to="/shop-single">Shop Details</NavLink></li>
                                                    <li><NavLink to="/cart-page">Cart Page</NavLink></li>
                                                </ul>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Blog</a>
                                                <ul className="submenu dropdown-menu">
                                                    <li><NavLink to="/blog">Blog</NavLink></li>
                                                    <li><NavLink to="/blog-2">Blog 2</NavLink></li>
                                                    <li><NavLink to="/blog-single">Blog Single</NavLink></li>
                                                </ul>
                                            </li> */}


                                            <li><NavLink to="/contact">Contact</NavLink></li>
                                        </ul>
                                       
                                        <a href="https://youtube.com/@wilgoldgames" target="_blank" className="button"> <span><img src="\assets\images\contact\yt3.png" alt="YouTube" /></span></a>

                                        <div className="header-bar d-lg-none" onClick={this.menuTrigger}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                        <div className="ellepsis-bar d-lg-none" onClick={this.menuTriggerTwo}>
                                            <i className="icofont-info-square"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
 
export default Header;