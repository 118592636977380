import { Component, Fragment } from "react";


import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import BannerTwo from '../component/section/bannertwo';
import CollectionSectionTwo from '../component/section/collectiontwo';
import AboutSection from "../component/section/about";
import MatchSectionTwo from '../component/section/matchtwo';
import PlayerSection from '../component/section/player';
import CtaSection from '../component/section/cta';
import VideoSectionTwo from '../component/section/videotwo';
import ProductSection from '../component/section/product';
import HrShape from '../component/layout/hrshape';
import SponsorSection from '../component/section/sponsor';
import BlogSection from '../component/section/blog';
import TestimonialSection from '../component/section/testimonial';
import LightBoxW from '../component/section/lightbox';
//import LightboxExample from '../component/section/springbox';
import GamePresentation from "../component/section/gamepresentation";

// import ScrollToTop from "../component/layout/scrolltop";

import ScrollTop from "react-scrolltop-button";



const images = [
    {
        sources:'assets/images/about/02.png',
    },
    {
        sources:"assets/images/about/02.png",
    }
]


class HomeTwo extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <BannerTwo />
                {/* <LightBoxW /> */}
                {/* <LightboxExample /> */}


                <PlayerSection />
                <AboutSection id='about' imgUrl={'assets/images/about/05.png'} />
                {/* <GamePresentation imgUrl={'assets/images/about/02.png'} /> */}
                <VideoSectionTwo />
                <CtaSection imgUrl={'assets/images/cta/02.png'} />


                
             {/*   <MatchSectionTwo /> 
                 
                <ProductSection />
                <HrShape />
                <SponsorSection />
                <CollectionSectionTwo />    */}
                {/* <BlogSection /> */}
                {/* <TestimonialSection /> */}
                <Footer />
                <ScrollTop
                    text="Scroll to top"
                    distance={500}
                    breakpoint={4555}
                    style={{ backgroundColor: "#e6a52d" }}
                    className="scroll-your-role"
                    speed={20}
                    target={0}
                    // icon={<MyIcon />}
                />


                {/* <ScrollToTop/> */}
            </Fragment>
        );
    }
}
 
export default HomeTwo;