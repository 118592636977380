import { Component, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Rating from "./rating";
import SocialMedia from "./socialmedia";

import ScrollTop from "react-scrolltop-button";

const subtitle = "Our Team Players";
const title = "Meet Our Squad Players";
const btnTextMore = "more";


let GameListData = [
    {
        id: 1,
        image: 'assets/images/game/01.png',
        alt: 'game list name',

        icon: [
            {
                iconName: 'icofont-star',
            },
        ],
        catagory: 'android',

        bgImgUrl: 'assets/images/team/7.jpg',
        imgUrl: 'assets/images/team/7.png',
        imgAlt: 'Player Thumb',
        title: 'Puppet Thread',
        gamelink: '/puppetthread',
        link: 'https://play.google.com/store/apps/details?id=com.klaydesigngames.puppetthread',
        linkIMG: 'assets/images/match/play.png',
        linkALT: 'Play Store',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'none',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',

    },
    {
        id: 2,
        image: 'assets/images/game/01.png',
        alt: 'game list name',

        icon: [
            {
                iconName: 'icofont-star',
            },
        ],
        catagory: 'steam',

        bgImgUrl: 'assets/images/team/9.jpg',
        imgUrl: 'assets/images/team/8b.png',
        imgAlt: 'Player Thumb',
        title: "Wilgold's Playground",
        gamelink: '/playground',
        link: 'http://www.wilgold.com',
        linkIMG: 'assets/images/match/itch.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'https://store.steampowered.com/app/2287050/Wilgolds_Playground/',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'Steam',
        show2: 'true',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'true',
    },
    {
        id: 3,
        image: 'assets/images/game/0.png',
        alt: 'game list name',

        icon: [
            {
                iconName: 'icofont-star',
            },
        ],
        catagory: '',

        bgImgUrl: 'assets/images/team/0.jpg',
        imgUrl: 'assets/images/team/0.png',
        imgAlt: 'Player Thumb',
        title: '???',
        gamelink: '/',
        link: '/',
        linkIMG: 'assets/images/match/0.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'none',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',
    },
  
]



const GameList = () => {

    const [items, setItems] = useState(GameListData);
    const filterItem = (categItem) => {
        const updateItems = GameListData.filter((curElem) => {
            return curElem.catagory.includes(categItem);
        });
        setItems(updateItems);
    }
    return (
        
        <section className="collection-section padding-top10 padding-bottom">
            <div className="container">
                <div className="section-wrapper">


                {/* <p className="theme-color text-uppercase section-header ls-2">Direct game stores</p>       */}


                <ul className="match-social-list d-flex flex-wrap align-items-center padding-bottom10 justify-content-center">
                        <Fragment className="swiper-slide">
                        <li>
                    <a href="https://store.steampowered.com/developer/Wilgold" target="_blank"><img src="assets/images/match/steam.png" alt="Steam" /></a>
                </li>
                        <li>
                    <a href="https://wilgold.itch.io" target="_blank"><img src="assets/images/match/itch.png" alt="Itch" /></a>
                </li>
                <li>
                    <a href="https://play.google.com/store/apps/dev?id=7707159057228301175" target="_blank"><img src="assets/images/match/play.png" alt="GooglePlay" /></a>
                </li>
                        </Fragment>
                    </ul>




              {/* <p className="theme-color text-uppercase section-header padding-top10 ls-2">filter games list</p>       */}
              <ul></ul>
                    <ul className="collection-filter-button-group common-filter-button-group d-flex flex-wrap justify-content-center mb-5 text-uppercase">  
                    {/* <p className="theme-color text-uppercase ls-2">Filter</p> */}
                        <li onClick={() => setItems(GameListData) }>All stores</li>
                        <li onClick={() => filterItem('steam') }>Steam</li>
                        <li onClick={() => filterItem('itch') }>Itch</li>
                        <li onClick={() => filterItem('android') }>Android</li>
                    </ul>


                    
                    <ScrollTop
                    text="Scroll to top"
                    distance={100}
                    breakpoint={4555}
                    style={{ backgroundColor: "#e6a52d" }}
                    className="scroll-your-role"
                    speed={20}
                    target={0}
                    // icon={<MyIcon />}
                />

                    <div className="container">
                    <div className="section-header ">
                        {/* <p>{subtitle}</p>
                        <h2>{title}</h2> */}
                    </div>
                    <div className="section-wrapper ">
                        <div className="row justify-content-md-center">
                            {items.map((val, i) => (
                                <div className="col-lg-4 col-sm-6 col-12 " key={i}>
                                    <div className="player-item-2 text-center " style={{backgroundImage: `url(${val.bgImgUrl})`}}>
                                        <div className="player-inner-2">
                                            <div className="player-thumb-2">
                                                <Link to={`${val.gamelink}`}>
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </Link>
                                            </div>
                                            <div className="player-content-2">
                                                <Link to={`${val.gamelink}`}><h2>{val.title}</h2></Link>
                                                <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                                                <Fragment>



<li style={{ display: val.show }}>

<a href={''+val.link} target="_blank"><img src={''+val.linkIMG} alt={''+val.linkALT}/></a>

</li>


<li style={{ display: val.show2 }}>

<a href={''+val.link2} target="_blank"><img src={''+val.linkIMG2} alt={''+val.linkALT2}/></a>
</li>

<li style={{ display: val.show3 }}>

<a href={''+val.link3} target="_blank"><img src={''+val.linkIMG3} alt={''+val.linkALT3}/></a>
</li>
</Fragment>
                                                </ul>
                                                <Link to={`${val.gamelink}`} className="default-button"><span>{btnTextMore} <i className="icofont-play-alt-1"></i></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                    
               
                </div>
            </div>

       

        </section>




    );
}
 



export default GameList;

