import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import ModalButton from './modalbutton-test';



const subtitle = "Top picks";
const title = "Some of the best games";
const btnText = "Browse All games";
const btnTextMore = "more";

let PlayerListContent = [
    {
        id: 1,
        image: 'assets/images/game/01.png',
        alt: 'game list name',

        icon: [
            {
                iconName: 'icofont-star',
            },
        ],
        catagory: 'android',

        bgImgUrl: 'assets/images/team/7b.jpg',
        imgUrl: 'assets/images/team/7.png',
        imgAlt: 'Player Thumb',
        title: 'Puppet Thread',
        gamelink: '/puppetthread',
        link: 'https://play.google.com/store/apps/details?id=com.klaydesigngames.puppetthread',
        linkIMG: 'assets/images/match/play.png',
        linkALT: 'Play Store',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'none',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',

    },
    {
        bgImgUrl: 'assets/images/team/9.jpg',
        imgUrl: 'assets/images/team/8b.png',
        imgAlt: 'Player Thumb',
        title: "Wilgold's Playground",
        gamelink: '/playground',
        link: 'http://www.wilgold.com',
        linkIMG: 'assets/images/match/itch.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'true',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'true',
    },
    {
        id: 3,
        image: 'assets/images/game/0.png',
        alt: 'game list name',

        icon: [
            {
                iconName: 'icofont-star',
            },
        ],
        catagory: 'android',

        bgImgUrl: 'assets/images/team/0.jpg',
        imgUrl: 'assets/images/team/0.png',
        imgAlt: 'Player Thumb',
        title: '???',
        gamelink: '/',
        link: '/',
        linkIMG: 'assets/images/match/0.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'none',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',
    },
    {
        id: 3,
        image: 'assets/images/game/0.png',
        alt: 'game list name',

        icon: [
            {
                iconName: 'icofont-star',
            },
        ],
        catagory: 'android',

        bgImgUrl: 'assets/images/team/0.jpg',
        imgUrl: 'assets/images/team/0.png',
        imgAlt: 'Player Thumb',
        title: '???',
        gamelink: '/',
        link: '/',
        linkIMG: 'assets/images/match/0.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'none',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',
    },
    {
        id: 3,
        image: 'assets/images/game/0.png',
        alt: 'game list name',

        icon: [
            {
                iconName: 'icofont-star',
            },
        ],
        catagory: 'android',

        bgImgUrl: 'assets/images/team/0.jpg',
        imgUrl: 'assets/images/team/0.png',
        imgAlt: 'Player Thumb',
        title: '???',
        gamelink: '/',
        link: '/',
        linkIMG: 'assets/images/match/0.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'none',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',
    },
]

class PlayerSection extends Component {
    render() { 
        return (
            <section className="player-section padding-top padding-bottom">
                <div className="container-fluid">
                    <div className="section-header">
                        <p>{subtitle}</p>
                        <h2>{title}</h2>
                    </div>



                    

                    <div className="section-wrapper">
                        <div className="player-slider">
                            <Swiper
                                spaceBetween={20}
                                slidesPerView={3}
                                loop={'true'}
                                loopFillGroupWithBlank={false}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}

                                
                                breakpoints={{
                                    0: {

                                        slidesPerView: 1,
                                    },
                                    480: {

                                        slidesPerView: 1,
                                    },
                                    768: {

                                        slidesPerView: 2,
                                    },
                                    1200: {
                                        
                                        slidesPerView: 3,
                                    },
                                    1920: {

                                        slidesPerView: 4,
                                    },
                                    2440: {

                                        slidesPerView: 5,
                                    }
                                }}








                            >
                                {PlayerListContent.map((val, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="player-item-2 text-center" style={{backgroundImage: `url(${val.bgImgUrl})`}}>
                                            <div className="player-inner-2">
                                                <div className="player-thumb-2">
                                                    <Link to={`${val.gamelink}`}>
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </Link>
                                                </div>
                                                <div className="player-content-2">
                                                    <Link to={`${val.gamelink}`}><h2>{val.title}</h2></Link>
                                                    <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                                                    

                                                    <Fragment>

                                                   

                <li style={{ display: val.show }}>

            <a href={''+val.link} target="_blank"><img src={''+val.linkIMG} alt={''+val.linkALT}/></a>

                </li>


                <li style={{ display: val.show2 }}>

            <a href={''+val.link2} target="_blank"><img src={''+val.linkIMG2} alt={''+val.linkALT2}/></a>
                </li>

                <li style={{ display: val.show3 }}>

            <a href={''+val.link3} target="_blank"><img src={''+val.linkIMG3} alt={''+val.linkALT3}/></a>
                </li>
            </Fragment>
                                                    </ul>

                                                    {/* <ModalButton /> */}

                                                    <Link to={`${val.gamelink}`} className="default-button"><span>{btnTextMore} <i className="icofont-play-alt-1"></i></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>


                            
                           

                            
                        </div>

                        
                    </div>

                    <div className="button-wrapper text-center mt-5">
                            <Link to="/games" className="default-button"><span>{btnText} <i className="icofont-circled-right"></i></span> </Link>
                        </div>
                    
                </div>


                
            </section>
        );
    }
}



 
export default PlayerSection;