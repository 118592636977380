import { Component } from "react";
import { Link } from "react-router-dom";


const title = <h2 className="mb-3">Support <span className="theme-color text-uppercase">Wilgold</span> to get new games and videos faster!</h2>;

const subtitle = "you can help as well";

const desc = 'A lot of coffee and energy drinks are needed to make games and videos.';

const btnText = 'Join Community';



class CtaSection extends Component {
    render() { 
        const {imgUrl} = this.props;
        return (
            <section className="cta-section padding-bottom">
                <div className="container">
                    <div className="cta-wrapper item-layer">
                        <div className="cta-item px-4 px-sm-5 pt-4 pt-sm-5 pt-lg-0" style={{backgroundImage: "url(/assets/images/cta/bg.jpg)"}}>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="cta-content">
                                        <p className="theme-color text-uppercase ls-2">{subtitle}</p>
                                        {title}
                                        <p className="mb-4">{desc}</p>

                                        <a href="https://www.buymeacoffee.com/wilgold" target="_blank"  className="default-button nolineheight"><span className="nolineheight"> 
                                            
                                         <input type="image" src="assets/images/cta/cof.png" /></span> </a>

                                        
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="cta-thumb text-end">

                                        <img src={imgUrl} alt="gamer-img" />

                                      
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


 
export default CtaSection;