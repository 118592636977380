import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GameList from "../component/section/gamelist";
import VideoSection from "../component/section/video";
import CtaSection from "../component/section/cta";

class GameListSection extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'All Games'} curPage={'GAMES'} />
                <GameList />
                {/* <VideoSection /> */}
                <CtaSection imgUrl={'assets/images/cta/02.png'} />
                <Footer />
            </Fragment>
        );
    }
}
 
export default GameListSection;