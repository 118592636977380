import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import SocialMedia from "./socialmedia";


const subtitle = "Our Team Players";
const title = "Meet Our Squad Players";
const btnTextMore = "more";

let PlayerListContent = [
    {
        bgImgUrl: 'assets/images/team/1.jpg',
        imgUrl: 'assets/images/team/1.png',
        imgAlt: 'Player Thumb',
        title: 'Dragon Team',
        gamelink: 'https://wilgold.com/puppetthread.html',
        link: 'http://www.wilgold.com',
        linkIMG: 'assets/images/match/itch.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'true',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'none',
    },
    {
        bgImgUrl: 'assets/images/team/6.jpg',
        imgUrl: 'assets/images/team/7.png',
        imgAlt: 'Player Thumb',
        title: 'Puppet Thread',
        gamelink: 'https://wilgold.com/puppetthread.html',
        link: 'http://www.wilgold.com',
        linkIMG: 'assets/images/match/itch.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'true',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'true',
    },
    {
        bgImgUrl: 'assets/images/team/3.jpg',
        imgUrl: 'assets/images/team/3.png',
        imgAlt: 'Player Thumb',
        title: 'Tigers Team',
        gamelink: 'https://wilgold.com/puppetthread.html',
        link: 'http://www.wilgold.com',
        linkIMG: 'assets/images/match/itch.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'true',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'true',
    },
    {
        bgImgUrl: 'assets/images/team/4.jpg',
        imgUrl: 'assets/images/team/4.png',
        imgAlt: 'Player Thumb',
        title: 'Shark Team',
        gamelink: 'https://wilgold.com/puppetthread.html',
        link: 'http://www.wilgold.com',
        linkIMG: 'assets/images/match/itch.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'true',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'true',
    },
    {
        bgImgUrl: 'assets/images/team/5.jpg',
        imgUrl: 'assets/images/team/5.png',
        imgAlt: 'Player Thumb',
        title: 'Gaming Team',
        gamelink: 'https://wilgold.com/puppetthread.html',
        link: 'http://www.wilgold.com',
        linkIMG: 'assets/images/match/itch.png',
        linkALT: 'Vimeo',
        show: 'true',
        link2: 'http://www.youtube.com',
        linkIMG2: 'assets/images/match/steam.png',
        linkALT2: 'YouTube',
        show2: 'true',
        link3: 'http://www.twitch.com',
        linkIMG3: 'assets/images/match/social-3.png',
        linkALT3: 'Twitch',
        show3: 'true',
    },
]


class PlayerSectionTwo extends Component {
    render() { 
        return (
            <section className="player-section padding-top padding-bottom">
                <div className="container">
                    <div className="section-header ">
                        <p>{subtitle}</p>
                        <h2>{title}</h2>
                    </div>
                    <div className="section-wrapper ">
                        <div className="row justify-content-md-center">
                            {PlayerListContent.map((val, i) => (
                                <div className="col-lg-4 col-sm-6 col-12 " key={i}>
                                    <div className="player-item-2 text-center " style={{backgroundImage: `url(${val.bgImgUrl})`}}>
                                        <div className="player-inner-2">
                                            <div className="player-thumb-2">
                                                <Link to={`${val.gamelink}`}>
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </Link>
                                            </div>
                                            <div className="player-content-2">
                                                <Link to={`${val.gamelink}`}><h2>{val.title}</h2></Link>
                                                <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                                                <Fragment>



<li style={{ display: val.show }}>

<a href={''+val.link} target="_blank"><img src={''+val.linkIMG} alt={''+val.linkALT}/></a>

</li>


<li style={{ display: val.show2 }}>

<a href={''+val.link2} target="_blank"><img src={''+val.linkIMG2} alt={''+val.linkALT2}/></a>
</li>

<li style={{ display: val.show3 }}>

<a href={''+val.link3} target="_blank"><img src={''+val.linkIMG3} alt={''+val.linkALT3}/></a>
</li>
</Fragment>
                                                </ul>
                                                <Link to={`${val.gamelink}`} className="default-button"><span>{btnTextMore} <i className="icofont-play-alt-1"></i></span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default PlayerSectionTwo;