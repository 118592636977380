import { Component, Fragment } from "react";


class SocialMedia extends Component {
    render() { 
        return (
            <Fragment>
                <li>
                    <a href="https://www.youtube.com/@wilgoldgames" target="_blank"><img src="assets/images/match/social-2.png" alt="Youtube" /></a>
                </li>
                <li>
                    <a href="https://www.tiktok.com/@wilgoldgames" target="_blank"><img src="assets/images/match/tiktok2.png" alt="TikTok" /></a>
                </li>
                <li>
                    <a href="https://www.facebook.com/wilgoldentertainment" target="_blank"><img src="assets/images/match/Facebook.png" alt="Facebook" /></a>
                </li>
                <li>
                    <a href="https://twitter.com/WilgoldGames" target="_blank"><img src="assets/images/match/twitter.png" alt="twitter" /></a>
                </li>
                <li>
                    <a href="https://soundcloud.com/wilgold" target="_blank"><img src="assets/images/match/soundcloud.png" alt="soundcloud" /></a>
                </li>
                <li>
                    <a href="https://www.buymeacoffee.com/wilgold" target="_blank"><img src="assets/images/match/grabmeacoffee.png" alt="grabmeacoffee" /></a>
                </li>
                <li>
                    <a href="https://patreon.com/wilgold" target="_blank"><img src="assets/images/match/patreon.png" alt="patreon" /></a>
                </li>

<div className="break"></div>
                <li>
                    <a href="https://store.steampowered.com/developer/Wilgold" target="_blank"><img src="assets/images/match/steam.png" alt="Steam" /></a>
                </li>
                <li>
                    <a href="https://wilgold.itch.io" target="_blank"><img src="assets/images/match/itch.png" alt="Itch" /></a>
                </li>
                <li>
                    <a href="https://play.google.com/store/apps/dev?id=7707159057228301175" target="_blank"><img src="assets/images/match/play.png" alt="GooglePlay" /></a>
                </li>
                


            </Fragment>
        );
    }
}
 
export default SocialMedia;